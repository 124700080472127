import * as React from "react"

import Home from "../components/dpcgc/Home"

const IndexPage = () => (
  <>
    <Home />
  </>
)

export default IndexPage
