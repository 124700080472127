/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider } from "react-apollo"
import client from "./src/apollo/client"
import "./src/components/dpcgc/home/members.css"
import "./src/components/dpcgc/faq.css"
import "./src/pages"
import Home from "./src/components/dpcgc/Home"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

export const wrapHomeElement = ({ element }) => (
  <ApolloProvider client={Home}>{element}</ApolloProvider>
)

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
