import { Box, Button, Divider, Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import dpcgclogo from "../../images/dpcgc/dpcgclogo.svg"
import iamailogo from "../../images/dpcgc/iamailogo.svg"
import dotsRafiki from "../../images/dpcgc/dots.svg"
import dotsRafiki2 from "../../images/dpcgc/dots2.svg"
import homePageRafiki from "../../images/dpcgc/homepage.jpg"
import reddotsRafiki from "../../images/dpcgc/redDots.svg"
import Footer from "./Footer"
import Members from "./home/Members"
import { MembersText } from "./home/membersText"

import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    background: "#243974",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#E0E0E0",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  bigheading: {
    fontSize: "60px",
    lineHeight: "70px",
    color: "#FFFFFF",
    fontWeight: "600",
    padding: "0 0 0 20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      lineHeight: "50px",
      padding: "0 40px 0 30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
      lineHeight: "35px",
      padding: "0 30px 0 30px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  content: {
    marginTop: "2rem",
  },
  objective: {
    fontSize: "20px",
    color: "#EB5757",
    fontWeight: 600,
    padding: "15px 15px 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  text: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "#F2F2F2",
    padding: "18px 0 0 18px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
  btn1: {
    width: "100%",
    background: "#EB5757",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      borderRadius: "10px",
      border: "1px solid #EB5757",
      color: "#EB5757",
      background: "#243974",
      transition: "1s",
    },
  },
  btn2: {
    width: "100%",
    background: "#243974",
    borderRadius: "4px",
    border: "1px solid #EB5757",
    color: "#EB5757",
    textTransform: "none",
    "&:hover": {
      borderRadius: "10px",
      color: "#FFFFFF",
      background: "#EB5757",
      transition: "1s",
    },
  },
  btntext: {
    fontSize: "16px",
    fontWeight: 500,
  },
  redText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#EB5757",
    marginBottom: "1rem",
  },
  verticalDivider: {
    background: "white",
    width: "1px",
  },
  boxBackground: {
    height: "18rem",
    margin: "-10px 0px",
    backgroundColor: "#243974",
    [theme.breakpoints.down("sm")]: {
      height: "13rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "13rem",
    },
  },
  rafiki: {
    marginTop: "6rem",
    height: "50rem",
    [theme.breakpoints.down("md")]: {
      height: "40rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30rem",
    },
  },
  duties: {
    color: "#EB5757",
    fontSize: "22px",
    fontWeight: "600",
    lineHeight: "25px",
    padding: "0 70% 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "0 35rem 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20rem 0 0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
  dots: {
    height: "7rem",
    [theme.breakpoints.down("sm")]: {
      height: "4rem",
    },
  },
  dots2: {
    height: "7rem",
    marginTop: "7rem",
    [theme.breakpoints.down("sm")]: {
      height: "4rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
  textDuties: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "22px",
    paddingRight: "40px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      padding: "0 20px",
    },
  },
  members: {
    color: "#EB5757",
    fontSize: "22px",
    lineHeight: "25px",
    fontWeight: "600",
  },
  membersName: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  lowertext: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "justify",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
  redDot: {
    height: "7rem",
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
  membersbox: {
    background: "#F2F2F2",
    padding: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
    },
  },
}))

export default function Home() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.root}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link to="https://www.iamai.in/" target="_blank">
              <img
                src={iamailogo}
                alt="iamai logo"
                className={classes.iamailogo}
                data-sal="fade"
                data-sal-delay="200"
                data-sal-duration="700"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
        <Box height="1rem" />
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{ padding: "0 10px" }}
        >
          <Grid item className={classes.grid}>
            <Link to="/whoweare" style={{ textDecoration: "none" }}>
              <Box height="16px" />
              <Typography className={classes.nav}>ABOUT US</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/grbmembers" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>GRB MEMBERS</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/govtnotification" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>
                GOVT. NOTIFICATIONS
              </Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/discolsures" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>DISCLOSURES</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/faqs" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>FAQS</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>LEADERSHIP TEAM</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            {/* <Box style={{ textAlign: "right" }}>
              <img src={newicon} alt="new" className={classes.newicon} />
            </Box> */}
            <Link to="/consumerresources" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>
                CONSUMER RESOURCES
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Box height="10rem" />
        <Grid
          container
          justifyContent="center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          <Grid item sm={10}>
            <Hidden smDown>
              <Typography className={classes.bigheading}>
                Digital Publisher Content
              </Typography>
              <Typography className={classes.bigheading}>
                Grievances Council
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography className={classes.bigheading}>
                Digital Publisher Content Grievances Council
              </Typography>
            </Hidden>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          <Grid item md={10} sm={10} xs={10}>
            <Box className={classes.content}>
              <Divider
                style={{ background: "white", width: "100%", height: "1px" }}
              />
              <Grid container style={{ width: "107%" }}>
                <Grid item sm={4} xs={3} style={{ textAlign: "right" }}>
                  <Typography className={classes.objective}>
                    Objective.
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider
                    orientation="vertical"
                    className={classes.verticalDivider}
                  />
                </Grid>
                <Grid item sm={7} xs={8}>
                  <Typography className={classes.text}>
                    The impact of online curated content in the lives of Indian
                    citizens is deep and pervasive primarily because of the
                    various manners in which it impacts and influences people.
                    DPCGC seeks to ensure that the responsibility of its member
                    companies must be discharged with full commitment to the IT
                    Rules and a deep understanding of the content that is
                    prohibited and regulated under various laws.
                    <br />
                    <br />
                    DPCGC aims to usher in a redressal mechanism which ensures a
                    balance between addressing viewer complaints and showcasing
                    content in free-speech environment without ad-hoc
                    interventions.
                  </Typography>
                  <Box height="2rem" />
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={3}
                    style={{ padding: "0 0 0 18px" }}
                  >
                    <Grid item md={5} sm={6} xs={12}>
                      <Button
                        className={classes.btn1}
                        onClick={() => {
                          window.location.href = "https://app.dpcgc.org"
                        }}
                      >
                        <Typography className={classes.btntext}>
                          File a grievance
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Box height="5rem" />
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={3}
                    style={{ padding: "0 0 0 18px" }}
                  >
                    <Grid item md={5} sm={6} xs={12}>
                      <Typography className={classes.redText}>
                        Are you a registered user?
                      </Typography>
                      <Button
                        className={classes.btn2}
                        onClick={() => {
                          window.location.href = "https://app.dpcgc.org"
                        }}
                      >
                        <Typography className={classes.btntext}>
                          Log in
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxBackground}>
        <img src={dotsRafiki} className={classes.dots} />
        <Grid
          container
          justifyContent="center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          <Grid item md={5} sm={5}>
            <img src={homePageRafiki} className={classes.rafiki} />
          </Grid>
          <Grid item md={5} sm={5}>
            <img src={dotsRafiki2} className={classes.dots2} />
            <Box height="2rem" />
            <Typography className={classes.duties}>
              Duties & Responsibilities.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.textDuties}>
              DPCGC shall guide Online Curated Content Providers in introducing
              greater specificity to facilitate self-regulation by the industry
              and minimize scope for subjective decisions in addressing
              complaints.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.textDuties}>
              The Grievance Redressal Board of DPCGC shall oversee and ensure
              the alignment and adherence by DPCGC member companies to the Code
              of Ethics; provide guidance to them on various aspects of the Code
              of Ethics; address grievances which have not been resolved by them
              within the specified period of fifteen days; hear appeals filed by
              the complainant against their decisions and issue guidance or
              advisories to publishers for ensuring compliance to the Code of
              Ethics. DPCGC members shall adhere to IT Rules 2021 and such other
              existing or new statutes, and Rules/Regulations/Guidelines framed
              thereunder from time to time, relating to publishing of online
              curated content.
            </Typography>
          </Grid>
        </Grid>
        <Box height="6rem" />
        <Grid container justifyContent="center">
          <Grid item>
            <Typography className={classes.members}>Members.</Typography>
          </Grid>
        </Grid>
        <Box height="2rem" />
        <Grid container justifyContent="center">
          <Grid item sm={7} xs={10}>
            <Typography className={classes.membersName}>
              {MembersText}
            </Typography>
          </Grid>
        </Grid>
        <Box height="2rem" />
        <Grid container justifyContent="center">
          <Grid
            item
            md={9}
            sm={11}
            xs={11}
            className={classes.membersbox}
            data-sal="slide-down"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            <Members />
          </Grid>
        </Grid>
        <Box height="7rem" />
        <Grid container justifyContent="center">
          <Grid
            item
            md={4}
            sm={5}
            xs={10}
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            <Grid container style={{ padding: "5px" }}>
              <Grid item style={{ padding: "3px" }}>
                <img src={reddotsRafiki} className={classes.redDot} />
              </Grid>
              <Grid item sm={8} xs={9} style={{ paddingLeft: "15px" }}>
                <Typography className={classes.members}>
                  Get in touch.
                </Typography>
                <Box height="2rem" />
                <Typography className={classes.lowertext}>
                  If you have any queries, please reach out to us on{" "}
                  <b> grievance@dpcgc.org</b> and we will get back to you as
                  soon as possible.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={5}
            xs={10}
            data-sal="slide-left"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            <Grid container style={{ padding: "5px" }}>
              <Grid item style={{ padding: "3px" }}>
                <img src={reddotsRafiki} className={classes.redDot} />
              </Grid>
              <Grid item sm={8} xs={9} style={{ paddingLeft: "15px" }}>
                <Typography className={classes.members}>
                  Technical Support
                </Typography>
                <Box height="2rem" />
                <Typography className={classes.lowertext}>
                  Send an email on <b>techsupport@webnyay.in</b> if you are
                  facing trouble registering a grievance.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box height="10rem" />
        <Footer />
      </Box>
    </>
  )
}
