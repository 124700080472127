// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-consumerresources-js": () => import("./../../../src/pages/consumerresources.js" /* webpackChunkName: "component---src-pages-consumerresources-js" */),
  "component---src-pages-discolsures-js": () => import("./../../../src/pages/discolsures.js" /* webpackChunkName: "component---src-pages-discolsures-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-govtnotification-js": () => import("./../../../src/pages/govtnotification.js" /* webpackChunkName: "component---src-pages-govtnotification-js" */),
  "component---src-pages-grbmember-1-js": () => import("./../../../src/pages/grbmember1.js" /* webpackChunkName: "component---src-pages-grbmember-1-js" */),
  "component---src-pages-grbmember-3-js": () => import("./../../../src/pages/grbmember3.js" /* webpackChunkName: "component---src-pages-grbmember-3-js" */),
  "component---src-pages-grbmember-5-js": () => import("./../../../src/pages/grbmember5.js" /* webpackChunkName: "component---src-pages-grbmember-5-js" */),
  "component---src-pages-grbmember-6-js": () => import("./../../../src/pages/grbmember6.js" /* webpackChunkName: "component---src-pages-grbmember-6-js" */),
  "component---src-pages-grbmember-7-js": () => import("./../../../src/pages/grbmember7.js" /* webpackChunkName: "component---src-pages-grbmember-7-js" */),
  "component---src-pages-grbmember-8-js": () => import("./../../../src/pages/grbmember8.js" /* webpackChunkName: "component---src-pages-grbmember-8-js" */),
  "component---src-pages-grbmembers-js": () => import("./../../../src/pages/grbmembers.js" /* webpackChunkName: "component---src-pages-grbmembers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadersspeak-js": () => import("./../../../src/pages/leadersspeak.js" /* webpackChunkName: "component---src-pages-leadersspeak-js" */),
  "component---src-pages-scrolltomembers-js": () => import("./../../../src/pages/scrolltomembers.js" /* webpackChunkName: "component---src-pages-scrolltomembers-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */)
}

