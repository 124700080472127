import React from "react"
import { Box, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    // height:'5rem',
    background: "#243974",
    textAlign: "center",
    padding: "1.8rem 0",
  },
  text: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#F2F2F2",
    [theme.breakpoints.down("xs")]:{
      fontSize:"12px",
    }
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <Box
      className={classes.root}
      // data-sal="slide-up"
      // data-sal-delay="200"
      // data-sal-duration="700"
    >
      <Typography className={classes.text}>
        To become a DPCGC Member, reach out to: d.sonali@iamai.in
      </Typography>
    </Box>
  )
}
