import React from "react"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import leftIcon from "../../../images/dpcgc/lefticon.svg"
import rightIcon from "../../../images/dpcgc/righticon.svg"
import "./members.css"

import Alt from "../../../images/png/Alt Balaji.png"
import miniTv from "../../../images/png/Amazon Mini TV.png"
import Prime from "../../../images/png/Amazon Prime.png"
import amc from "../../../images/png/AMC.png"
import arhaMedia from "../../../images/arha.svg"
import CuriosityStream from "../../../images/CuriosityStream.svg"
import Nammaflix from "../../../images/Nammaflix.jpg"
import Arre from "../../../images/png/Arre.png"
import Bms from "../../../images/png/BookMyShow.png"
import BBCStudios from "../../../images/png/BBC.png"
import Chaupal from "../../../images/png/Chaupal.jpg"
import DangalPlay from "../../../images/png/DangalPlay.jpg"
import erosnow from "../../../images/png/Eros.png"
import Fiewwork from "../../../images/png/Firework.png"
import Hoichoi from "../../../images/png/Hoichoi.png"
import Hungama from "../../../images/png/Hungama.png"
import Ibadat from "../../../images/png/Ibadat.png"
import iWonder from "../../../images/png/iwonder.png"
import Kancchalannka from "../../../images/png/kancchalannka.png"
import KCGlobal from "../../../images/png/KC_global.png"
import Klikk from "../../../images/png/Klikk.jpg"
import lionsgate from "../../../images/png/Lionsgate Play.png"
import ShemarooBhakti from "../../../images/png/Logos.png"
import MovieTube from "../../../images/png/Movie Tube.png"
import Mubi from "../../../images/png/Mubi_logo.png"
import MuseumTv from "../../../images/png/MUSEUMTV.png"
import Mxplayer from "../../../images/png/MX Player.png"
import MyMango from "../../../images/png/MyMango.png"
import MyZenTv from "../../../images/png/MyZenTv.png"
import Mzaalo from "../../../images/png/Mzaalo_Logo.png"
import Netflix from "../../../images/png/Netflix.png"
import PlanetMarathi from "../../../images/png/Planet Marathi.png"
import Reeldrama from "../../../images/png/ReelDrama.png"
import Shemaroo from "../../../images/png/Shemaroo.png"
import Stage from "../../../images/png/Stage_Logo.png"
import Stingray from "../../../images/png/Stingray.png"
import TheAnimeTimes from "../../../images/png/The_Anime_Times_Logo.png"
import TubeNight from "../../../images/png/Tube Night.png"
import Ullu from "../../../images/png/Ullu.png"
import vrott from "../../../images/png/vrott.png"

const getItems = [
  { id: 1, icon: Alt },
  { id: 2, icon: miniTv },
  { id: 3, icon: Prime },
  { id: 4, icon: amc },
  { id: 5, icon: arhaMedia },
  { id: 6, icon: Arre },
  { id: 39, icon: BBCStudios },
  { id: 7, icon: Bms },
  { id: 8, icon: Chaupal },
  { id: 34, icon: CuriosityStream },
  { id: 35, icon: DangalPlay },
  { id: 9, icon: erosnow },
  { id: 10, icon: Fiewwork },
  { id: 11, icon: Hoichoi },
  { id: 12, icon: Hungama },
  { id: 13, icon: Ibadat },
  { id: 14, icon: iWonder },
  { id: 33, icon: Kancchalannka },
  { id: 40, icon: KCGlobal },
  { id: 15, icon: Klikk },
  { id: 16, icon: lionsgate },
  { id: 17, icon: MyMango },
  { id: 18, icon: Mubi },
  { id: 19, icon: MuseumTv },
  { id: 20, icon: Mxplayer },
  { id: 21, icon: MovieTube },
  { id: 22, icon: MyZenTv },
  { id: 38, icon: Mzaalo},
  { id: 23, icon: Nammaflix },
  { id: 24, icon: Netflix },
  { id: 25, icon: PlanetMarathi },
  { id: 26, icon: Reeldrama },
  { id: 27, icon: ShemarooBhakti },
  { id: 28, icon: Shemaroo },
  { id: 36, icon: Stage },
  { id: 29, icon: Stingray },
  { id: 37, icon: TheAnimeTimes },
  { id: 30, icon: TubeNight },
  { id: 31, icon: Ullu },
  { id: 32, icon: vrott },
]

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  //   if(isFirstItemVisible) return null
  return (
    <img
      src={leftIcon}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      className="lefticon"
    ></img>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
  // if(isLastItemVisible) return null
  return (
    <img
      src={rightIcon}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      className="lefticon"
    ></img>
  )
}

function Card({ item, itemId, title, onClick, selected }) {
  const visibility = React.useContext(VisibilityContext)

  return (
    <div
      // onClick={() => onClick(visibility)}
      // className= {item.id == 11 ? "memberwidth1" : "memberwidth"}
      className="memberwidth"
      tabIndex={0}
      style={{ textAlign: "center" }}
    >
      <img
        src={item.icon}
        className={
          item.id == 4 ||
          item.id == 5 ||
          item.id == 6 ||
          item.id == 23 ||
          item.id == 14 ||
          item.id == 33
            ? "arhaicon"
            : item.id == 8 ||
              item.id == 22 ||
              item.id == 32 ||
              item.id == 15 ||
              item.id == 35 ||
              item.id == 37 ||
              item.id == 38
            ? "membersicons1"
            : item.id == 29
            ? "stingrayicon"
            : item.id == 17
            ? "mymangoicon"
            : item.id == 19
            ? "museumicon"
            : item.id == 36
            ? "stageicon"
            : "membersicons"
        }
        alt="icon"
        // className={item.id == 4 ? "arhaicon" :  "membersicons"}
        // className="membersicons"
        //   onClick={() => onClick(visibility)} tabIndex={0}
      />
    </div>
  )
}

export default function Members() {
  const [items, setItems] = React.useState(getItems)
  const [selected, setSelected] = React.useState([])
  const [position, setPosition] = React.useState(0)

  const isItemSelected = id => !!selected.find(el => el === id)

  const handleClick = id => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id)

    setSelected(currentSelected =>
      itemSelected
        ? currentSelected.filter(el => el !== id)
        : currentSelected.concat(id)
    )
  }

  items.map(item => {
    console.log("item", item)
  })

  return (
    // <Box style={{  }}>
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {items.map(item => (
        <Card
          itemId={item.id} // NOTE: itemitem is required for track items
          title={item.id}
          onClick={handleClick(item.id)}
          selected={isItemSelected(item.id)}
          item={item}
          key={item.id}
        />
      ))}
    </ScrollMenu>
    // {/* </Box> */}
  )
}
